/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ColumnFilter,
    DiagnosisDto,
    DiagnosisDtoListResponseModel,
    DiagnosisDtoModelResponse,
    GeneralStatus,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class Diagnosis<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Diagnosis
     * @name PostDiagnosis
     * @request POST:/Diagnosis/Add
     */
    postPost = (data: DiagnosisDto, params: RequestParams = {}) => this.postDiagnosis(data, params)

    /**
     * No description
     *
     * @tags Diagnosis
     * @name PostDiagnosis
     * @request POST:/Diagnosis/Add
     */
    postDiagnosis = (data: DiagnosisDto, params: RequestParams = {}) =>
        this.request<DiagnosisDtoModelResponse, any>({
            path: `/Diagnosis/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Diagnosis
     * @name LoadList
     * @request GET:/Diagnosis/Load
     */
    loadList = (
        query?: {
            Take?: number;
            Skip?: number;
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            PracticeId?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<DiagnosisDtoListResponseModel, any>({
            path: `/Diagnosis/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Diagnosis
     * @name GetByIdList
     * @request GET:/Diagnosis/GetById
     */
    getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<DiagnosisDtoModelResponse, any>({
            path: `/Diagnosis/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Diagnosis
     * @name DeleteDelete
     * @request DELETE:/Diagnosis/Delete
     */
    deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
        this.request<DiagnosisDtoModelResponse, any>({
            path: `/Diagnosis/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags Diagnosis
     * @name UpdateCreate
     * @request POST:/Diagnosis/Update
     */
    updateCreate = (data: DiagnosisDto, params: RequestParams = {}) =>
        this.request<DiagnosisDtoModelResponse, any>({
            path: `/Diagnosis/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
